<template>
  <div class="event">
    <div class="event-inner">
      <div class="event-banner" ref="event-banner">
        <img src="../../assets/coming-soon.png" :alt="name" v-if="comingSoon" />
        <img :src="require(`../../assets/${image}`)" :alt="name" v-else />
      </div>
      <div class="bottom" @click="expand">
        <div class="event-title">{{ name }}</div>

        <div class="event-details">
          <div class="event-details-inner" :class="{ active: expanded }">
            <div
              class="detail"
              :class="{ disabled: comingSoon }"
              v-for="(op, index) in options"
              :key="index"
            >
              <span v-if="comingSoon">
                {{ op.name }}
              </span>
              <span @click="readArticle" v-else>
                {{ op.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="event-details-toggle" :class="{ active: expanded }">
          <font-awesome-icon icon="angle-down" />
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faAngleDown);

export default {
  name: "blog-tile",
  components: {
    FontAwesomeIcon
  },
  props: {
    comingSoon: Boolean,
    image: String,
    link: String,
    name: String,
    options: Array
  },
  data() {
    return {
      expanded: false
    };
  },
  mounted() {
    this.$refs["event-banner"].style.height =
      this.$refs["event-banner"].clientWidth / 2 + "px";
  },
  methods: {
    expand() {
      if (this.expanded) this.expanded = false;
      else this.expanded = true;
    },
    close() {
      if (this.expanded) {
        this.expanded = false;
      }
    },
    readArticle() {
      this.$router.push(`/resources/${this.link}`);
    }
  }
};
</script>

<style lang="less" scoped>
.event {
  flex-grow: unset;
  margin: 12px 12px 0 0;
  padding: 6px;
  position: relative;
  width: calc(31.5% - 11px);
  background-color: var(--event-background);
  border: 1px solid var(--event-border);
  border-radius: 4px;
  box-shadow: var(--event-boxshadow);
  overflow: hidden;

  @media screen and (max-width: 971px) {
    margin: 1em 0.75%;
    width: calc(48.5% - 14px);
  }

  @media screen and (max-width: 724px) {
    margin: 1em 1%;
    width: 98%;
  }

  .event-inner {
    // cursor: pointer;

    .event-banner {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s;

        &.cancelled {
          position: absolute;
          height: 240px;
          right: 2px;
          top: -22px;
        }
      }
    } // end event-banner

    .event-title {
      margin: 0.25em 0;
      font-family: inherit;
      font-size: 1.18em;
      font-weight: 700;
      letter-spacing: -0.05em;
    } // end event-title

    .event-date {
      font-family: inherit;
      color: #737373;
    } // end event-date

    .event-details {
      margin: 0 -6px;

      .event-details-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-height: 0;
        overflow: hidden;
        transition: 0.4s;

        &.active {
          max-height: 1000px;
        }

        .detail {
          margin: 4px 0;
          padding: 0.5em 0;
          width: 47%;
          background: #33363d;
          text-align: center;
          text-transform: capitalize;
          color: #fff;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          a {
            color: white;
          }
        }

        a.detail {
          position: relative;
          z-index: 1;
          width: 46.5%;

          color: white;
          filter: brightness(1.1);
          background-color: #222222;
          background: var(--event-detail-background);
          border: 1px solid var(--event-detail-border);
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 101%;
            height: 100%;
            background: linear-gradient(#353535, #202020);
            opacity: 0;
            transition: 0.4s;
          }

          &:hover::before {
            opacity: 1;
          }
        }
      } // end event-details inner
    } // end event-details

    .event-details-toggle {
      position: relative;
      top: -1em;
      z-index: 3;
      left: 80%;
      width: 20%;
      max-height: 0px;
      text-align: right;
      color: #666666;
      font-size: 1.25em;
      cursor: pointer;
      transition: 0.4s;

      &.active {
        top: 0;
        max-height: 20px;

        svg {
          transform: rotateX(180deg);
        }
      }
    } // end event-details-toggle
  } // end event-inner
}
</style>