<template>
  <div class="content-container">
    <loader
      site="planners"
      :active="loading"
      message="Hang on tight. We're loading latest data."
    />
    <div v-if="blogList">
      <breadcrumbs :crumbs="breadcrumbs" />

      <h1 class="content-header">{{ article.name }}</h1>
      <div
        class="content-inner-container"
        v-for="(blog, index) in blogList"
        :key="index"
      >
        <h2 v-html="blog.title.rendered"></h2>
        <div class="blog-container" v-html="limitBlogPost(blog)"></div>
        <FormulateInput
          type="button"
          label="Read More"
          @click="readMore(blog)"
        />
        <div v-if="addTag(index, blogList)">
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { getArticle } from "../../helpers/blog.js";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "blog-list",
  components: {
    Loader,
    Breadcrumbs
  },
  props: {
    articleLink: String
  },
  data() {
    return {
      breadcrumbs: [
        ["Resources", "/resources"],
        ["Blogs", null]
      ],
      article: null,
      blogList: null,
      loading: true
    };
  },
  computed: {},
  methods: {
    addTag(index, blogList) {
      return index !== blogList.length - 1;
    },
    limitBlogPost(blog) {
      return blog.content.rendered.substr(0, 1000) + "...";
    },
    readMore(blog) {
      this.$router.push(`/resources/${this.articleLink}/${blog.slug}`);
    }
  },
  created() {
    this.$store.commit("setTitle", "Resources");
    this.$store.dispatch("updateHelpVideoSrc", null);
    this.article = getArticle(this.articleLink);
  },
  async mounted() {
    try {
      if (this.article) {
        const { data } = await this.$axios.get(
          `https://resources.incredevent.com/wp-json/wp/v2/posts?categories=${this.article.categoryNumber}`
        );
        this.blogList = data;
        this.loading = false;
      } else {
        this.$router.push(`/resources`);
      }
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style scoped lang="less">
.content-container {
  margin: auto;
  padding-top: 15px;
  padding-bottom: 100px;
  position: relative;
  width: 94%;
  max-width: 1100px;
  overflow: visible;

  .content-header {
    margin: auto;
    text-align: center;
  }
  .content-inner-container {
    &::v-deep .blog-container {
      ul,
      ol {
        padding-left: 50px;
      }
      figure {
        text-align: center;
        margin-top: 18px;
        img,
        video {
          width: 94%;
          max-width: 1100px;
          text-align: center;
        }
      }
    }
  }
}
</style>
