// categoryNumber is coming from wordpress
export const articleList = [
  {
    categoryNumber: 6,
    comingSoon: false,
    image: "business.png",
    link: "business-articles",
    name: "Business Articles",
    options: [{ name: "Read Articles" }]
  },
  {
    categoryNumber: 7,
    comingSoon: false,
    image: "event_management.png",
    link: "event-management-articles",
    name: "Event Management Articles",
    options: [{ name: "Read Articles" }]
  },
  {
    categoryNumber: 4,
    comingSoon: false,
    image: "marketing.png",
    link: "marketing-articles",
    name: "Marketing Articles",
    options: [{ name: "Read Articles" }]
  },
  {
    categoryNumber: 3,
    comingSoon: false,
    image: "ticketing.png",
    link: "ticketing-articles",
    name: "Ticketing Articles",
    options: [{ name: "Read Articles" }]
  },
  {
    categoryNumber: 5,
    comingSoon: false,
    image: "features.png",
    link: "tickettote-features-articles",
    name: "INCREDEVENT Features Articles",
    options: [{ name: "Read Articles" }]
  }
];

export const getArticle = articleLink => {
  return articleList.find(article => article.link === articleLink);
};
