<template>
  <div>
    <loader
      site="planners"
      :active="loading"
      message="Hang on tight. We're loading latest data."
    />
    <div class="content-container" v-if="blog">
      <h1 class="content-header" v-html="blog.title.rendered"></h1>
      <div class="blog-container" v-html="blog.content.rendered"></div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  name: "blog",
  components: {
    Loader
  },
  props: {
    articleLink: {
      type: String,
      default: null
    },
    slug: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      blog: null,
      loading: true
    };
  },

  created() {
    this.$store.commit("setTitle", "Resources");
    this.$store.dispatch("updateHelpVideoSrc", null);
  },
  async mounted() {
    try {
      const { data } = await this.$axios.get(
        `https://resources.incredevent.com/wp-json/wp/v2/posts?slug=${this.slug}`
      );

      if (data[0]) {
        this.blog = data[0];
        this.loading = false;
      } else {
        this.$router.push(`/resources`);
      }
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style scoped lang="less">
.content-container {
  margin: auto;
  padding-top: 15px;
  padding-bottom: 100px;
  position: relative;
  width: 94%;
  max-width: 1100px;
  overflow: visible;
  .content-header {
    margin: auto;
    text-align: center;
  }
  &::v-deep .blog-container {
    ul,
    ol {
      padding-left: 50px;
    }
    figure {
      text-align: center;
      margin-top: 18px;
      img,
      video {
        width: 94%;
        height: auto;
        max-width: 1100px;
        text-align: center;
      }
    }
  }
}
</style>
