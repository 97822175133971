<template>
  <div class="security">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="tiles">
          <blog-tile
            v-for="(article, index) in articleList"
            :key="index"
            :comingSoon="article.comingSoon"
            :image="article.image"
            :link="article.link"
            :name="article.name"
            :options="article.options"
          ></blog-tile>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import BlogTile from "./BlogTile.vue";
import { articleList } from "../../helpers/blog.js";

export default {
  name: "resources",
  components: {
    BlogTile
  },
  head() {
    return {
      title: "Resources",
      tiles: [
        {
          name: ""
        }
      ]
    };
  },
  data() {
    return {
      articleList: articleList
    };
  },
  created() {
    this.$store.commit("setTitle", "Resources");
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>

<style lang="less" scoped>
div.tiles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
h2 {
  padding-top: 0;
}
noscript {
  color: #ffffff;
}
</style>